@tailwind base;
@tailwind components;
@tailwind utilities;

select {
  background-image: none !important;
}

.paginator {
  direction: ltr;
}
